import React from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "gatsby";
import FullBody from "../components/graphics/FullBody";
import Background from "../components/graphics/Background";
import { speak } from "../utils";
import Voices from "../components/Voices";
import Layout from "../components/layout";

const Container = styled.div`
  text-align: center;
`;
const blink = keyframes`
0%{
  color: #fff
}
50% {
  color: #ccc
}
100%{
  color: #fff
}
`;
const bouncy = keyframes`
0%{
  transform: scale(1)
}
50% {
  transform: scale(1.1)
}
60% {
  transform: scale(0.95)
}
100%{
  transform: scale(1)
}
`;
const Button = styled(Link)`
  box-shadow: 5px 5px 0 #ae125b;
  font-size: 35px;
  text-decoration: none;
  font-family: "Patrick Hand", sans-serif;
  padding: 10px 40px;
  border-radius: 40px;
  color: #fff;
  border: 0;
  background: #e60e7a;
  outline: none;
  cursor: pointer;
  &:hover {
    box-shadow: 1px 2px 0 #ae125b;
    transform: translate(3px, 3px);
    animation: ${blink} 0.25s linear infinite;
  }
`;
const FlippedMirror = styled.div`
  margin-right: 20px;
`;

const MirrorContent = styled.div`
  clip-path: ellipse(100px 200px at center);
  transform: scaleX(-1) skewX(-5deg);
  height: 420px;
  background: #fff;
  background: linear-gradient(
    -20deg,
    rgba(143, 192, 228, 1) 0%,
    rgba(250, 250, 250, 1) 30%,
    rgba(250, 250, 250, 1) 60%,
    rgba(143, 192, 228, 1) 100%
  );
`;
const MirrorStand = styled.div`
  align-self: center;
  padding-left: 20px;
  > div {
    border-radius: 4px;
    width: 20px;
    background: #bc8e63;
    height: 200px;
    transform: skewX(-15deg) translateY(-20px);
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
const SceneContainer = styled.div`
  display: flex;
  position: relative;
  margin: 60px auto 0 auto;
`;
const Description = styled.div`
  margin: -50px auto 0 auto;
  font-weight: 600;
  font-family: "Barlow", sans-serif;
  font-size: 30px;
  line-height: 35px;
  color: #555;
  width: 500px;
`;
const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: -10;
  ${({ backdrop }) =>
    backdrop &&
    `
  opacity: 0.1
`}
`;
const Dialog = styled.div`
  animation: ${bouncy} 0.5s ease-in-out infinite;
  width: 160px;
  transform-origin: center center;
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 93%,
    31% 93%,
    14% 100%,
    16% 93%,
    0 93%
  );
  font-family: "Patrick Hand", sans-serif;
  font-size: 25px;
  line-height: 30px;
  background: #52a4e0;
  padding: 5px 10px 15px 10px;
  color: #fff;
  border-radius: 5px;
  z-index: -5;
  margin-top: -20px;
  margin-left: -5px;
  position: relative;
`;

const DialogWrapper = styled.div`
  cursor: pointer;
  &:hover ${Dialog} {
    animation: none;
  }
  ${({ speaking }) =>
    speaking &&
    `
 ${Dialog} {
  animation: none
 }
 `}
`;

const Content = () => {
  const [speaking, setSpeaking] = React.useState(false);
  const [voice, setVoice] = React.useState();

  const talk = React.useCallback(
    (msg, pitch = 1.3) => {
      if (!voice) return;
      setSpeaking(true);
      speak(msg, voice, pitch, () => {
        setSpeaking(false);
      });
    },
    [setSpeaking, voice]
  );

  const [open, setOpen] = React.useState(false);

  const handleTalkBrief = React.useCallback(() => {
    if (!voice) {
      setOpen(true);
    } else {
      talk("I hope Santa will notice me...yeaahhhh");
    }
  }, [talk, setOpen, voice]);

  const handleNavigate = React.useCallback(
    (e) => {
      if (!voice) {
        setOpen(true);
        e.preventDefault();
      } else {
        talk("Let's Get Dressed!", 1.6);
      }
    },
    [talk]
  );

  return (
    <Layout>
      <Container>
        <ContentContainer>
          <BackgroundContainer backdrop>
            <Background />
          </BackgroundContainer>
          <Voices
            onSelect={(v) => setVoice(v)}
            selectedVoice={voice}
            open={open}
            setOpen={setOpen}
          />
          <SceneContainer>
            <MirrorStand>
              <div />
            </MirrorStand>

            <FlippedMirror>
              <MirrorContent>
                <FullBody width={200} flipped animated variant="bigHead" />
              </MirrorContent>
            </FlippedMirror>

            <FullBody width={200} animated variant="bigHead" />
            <div>
              <DialogWrapper
                style={{ position: "relative" }}
                onClick={handleTalkBrief}
                speaking={speaking}
              >
                <Dialog>I hope Santa will notice me...🔊</Dialog>
              </DialogWrapper>
            </div>
          </SceneContainer>

          <Description>
            <div style={{ marginBottom: 20 }}>
              Help lil Reindeer pick the best outfit to impress santa at the
              Christmas Party!
            </div>
            <div>
              <Button
                to="/dressing-room"
                disabled={!voice}
                onClick={handleNavigate}
              >
                Let's Get Dressed!
              </Button>
            </div>
          </Description>
        </ContentContainer>
      </Container>
    </Layout>
  );
};
export default Content;
