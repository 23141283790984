export const getVoices = (speechSynthesis) => {
  return new Promise((resolve) => {
    let voices = speechSynthesis.getVoices();
    if (voices.length) {
      resolve(voices);
      return;
    }
    speechSynthesis.onvoiceschanged = () => {
      voices = speechSynthesis.getVoices();
      resolve(voices);
    };
  });
};

export const speak = async (message, voice, pitch = 1.3, callback) => {
  var synth = window.speechSynthesis;
  var msg = new SpeechSynthesisUtterance(message);
  msg.voice = voice;
  msg.pitch = pitch;
  synth.speak(msg);
  msg.onend = callback;
};
