import React from "react";
import styled from "styled-components";
import { useVoiceContext } from "../contexts";
import { speak } from "../utils";

const VoiceContainer = styled.button`
  font-family: "Patrick Hand", sans-serif;
  display: block;
  font-size: 40px;
  border: 0;
  background: transparent;
  outline: none;
  cursor: pointer;
  padding: 10px 20px;
  ${({ selected }) =>
    selected &&
    `
    color: red;
  `}
  &:hover {
    color: #999;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 10px;
`;

const Backdrop = styled.div`
  background: rgba(255, 255, 255, 0.5);
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow: auto;
  justify-content: flex-end;
`;
const Content = styled.div`
  width: 20vw;
  height: 80vh;
  min-width: 300px;
`;
const TextButton = styled.button`
  font-family: "Barlow", sans-serif;
  outlined: none;
  font-size: 30px;
  width: 200px;
  background: transparent;
  border: 0;
  cursor: pointer;
`;
const Header = styled.h1`
  font-family: "Patrick Hand", sans-serif;
`;
const IconButton = styled.button`
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 20px;
  background: #fff;
  border: 0;
  font-family: "Barlow", sans-serif;
`;
const Voices = ({ selectedVoice, onSelect, open, setOpen }) => {
  const { voices } = useVoiceContext();
  const handleSelect = React.useCallback(
    (voice) => {
      speak(`Ho Ho Ho! You are ${voice.name}`, voice, 1.3);
      onSelect(voice);
    },
    [onSelect, speak]
  );
  const handleClose = React.useCallback(
    (e) => {
      if (e.target.tagName.toLowerCase() !== "button") {
        setOpen(false);
      }
    },
    [setOpen]
  );
  return (
    <Wrapper>
      {!open && (
        <IconButton onClick={() => setOpen((prev) => !prev)}>⚙️</IconButton>
      )}
      {open && (
        <Backdrop onClick={handleClose}>
          <div>
            <Content>
              <Header>Choose a character maybe?</Header>
              <IconButton onClick={() => setOpen((prev) => !prev)}>
                Ok. I am done
              </IconButton>

              {voices.map((voice) => (
                <VoiceContainer
                  key={voice.voiceURI}
                  selected={
                    selectedVoice && selectedVoice.voiceURI === voice.voiceURI
                  }
                  onClick={() => handleSelect(voice)}
                >
                  {selectedVoice &&
                    selectedVoice.voiceURI === voice.voiceURI && <>🌱</>}
                  {voice.name}
                </VoiceContainer>
              ))}
            </Content>
          </div>
        </Backdrop>
      )}
    </Wrapper>
  );
};
export default Voices;
